<template>
  <div id="step" v-if="step" class="px-3" :class="{'mobile':$vuetify.breakpoint.smAndDown}">
    <v-card color="white" class="current col-12" style="margin:0 auto">
      <!-- <small class="time grey--text">{{step.timestamp}}</small> -->
        <h3 class="time grey--text text--darken-1">{{step.title || `Step ${parseFloat(currentMarker.index)+1}`}}</h3>
        <!-- <div class="time grey--text">{{JSON.parse(step.description)}}</div> -->
        <div class="description grey--text text--darken-1" v-html="step.description"></div>
    </v-card>
  </div>
</template>

<script>

export default {
  name: "step",
  props: ['step', 'currentMarker', 'isPlaying'],
  data(){
    return{
      avatarSize: 32,
      bounceIndex: 9999999999,
    }
  },
  mounted(){
    process.env.NODE_ENV === "development" ? console.log( 'step loaded', this.step ) : null;
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
iframe, img{
  max-width: 100%;
}
#step{
  padding-bottom: 90vh;
  &.mobile{
    h3{
      font-size: 94%;
      padding-bottom: 8px; 
    }
    .description{
      font-size: 84%;
    }
  }


}
</style>
